* /* Form styles */
input,
textarea {
    outline: none;
    border: none;
    background: none;
    caret-color: black;
}
::placeholder {
  color: var(--placeholder-color); 
  transition: all var(--speed);
  font-size: 1rem;
}

/* Linear progress styles */
.linear-progress {
    width: 100%;
    height: 4px;
    background: var(--primary-color);
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.linear-progress::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--primary-color-light);
    animation: progress 2s linear infinite;
}
@keyframes progress {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
}
.avatar {
    border: solid 1px var(--border-color);
}
/* label styles */ 
.label {
    position: relative;
    display: block;    
    color: var(--primary-color);
}
.label::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    border-bottom: 1px solid #999;
    transition: all var(--speed);
}  
.label:focus-within::before {
    width: 100%;
    border-color: var(--primary-color);
}

/* input styles */
.basic-input {
    background: var(--input-background-color);
    color: var(--input-text-color);
    padding: 10px;
}
.input {    
    display: block;
    width: 100%;
    border-bottom: solid 1px var(--input-border-color);
    padding: 20px 10px;
    background: var(--input-background-color);
    transition: border-color var(--speed);
    color: var(--input-text-color);
    caret-color: black;
}
.input:hover {
    border-color: var(--primary-color);
}
.input:focus {
    border-color: var(--primary-color);
}  
/* small styles */
.label small {
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 0;
    transition: all var(--speed) linear;
}
.label .input:focus + small,
.focusable-div:focus + small {
    font-size: 10px;
    left: 0;
    top: -16px;
    opacity: 1;
}
/* Select styles */
.select-dropdown {    
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;  
    background: var(--input-background-color);
    z-index: 2;
    overflow-y: auto;   
    box-shadow: var(--box-shadow);     
}
.select-dropdown.modal-input {
    position: fixed;
    width: auto;
    top: 0;
}
.select-dropdown li {
    padding: 10px;
    border: solid 1px var(--border-color);
    border-top: none;
    cursor: pointer;
    background: var(--select-background-color);
    transition: all var(--speed);
    color: var(--input-text-color);
    /* height: 69px;  */
    min-width: 200px;   
}
.select-dropdown li .select-label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.select-dropdown li:hover {
    background: var(--select-background-color-hover);
}
.select-container input {
    min-height: 63px;
}
.parent .select-container,
.parent .label,
.mobile-parent .label {
    flex: 1;
    width: 100%;
}

/* Radio styles */
.radio-button-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: none;
    border-radius: 50%;
    background-position: center;
    transition: background 0.5s, box-shadow var(--speed);   
    background-color: none;
    cursor: pointer;
}
.radio-button-container:hover {
    background: 
    var(--icon-button-background-hover) 
    radial-gradient(circle, transparent 1%, var(--icon-button-background-hover) 1%) center/15000%;
}
.radio-button-container:active {
    background-color: var(--icon-button-background);
    background-size: 100%;
    transition: all 0s;
}
.radio-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: solid 2px var(--icon-color);
    background: var(--background);
    border-radius: 50%;
    transition: all var(--speed);
}
.radio-button:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: none;
    border-radius: 50%;
}
.radio-button-container:hover .radio-button,
.radio-button-container:hover .radio-button.checked,
.radio-button-container:hover .radio-button.checked:after,
.radio-button-container:active .radio-button{
    border-color: var(--icon-color-hover);
}

.radio-button-container:active .radio-button:after {
    background: var(--icon-color-hover);
}
.radio-button.checked {
    border-color: var(--icon-color);
}
.radio-button.checked:after {
    background: var(--icon-color);
}
  
/* placeholder styles */
.input::placeholder {
    color: var(--placeholder-color); 
    transition: all var(--speed);
    font-size: 1rem;
}
.input:focus::placeholder {
    font-size: 0;
}
.adornment-end {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
}

.user-form {       
    border: solid 1px var(--border-color);   
    padding: 40px;
    width: 100%;
    max-width: 480px;
    border-radius: 5px;
    margin: 0 auto;
    box-shadow: var(--box-shadow);
}
.admin-form {       
    border: none;   
    max-width: none;
    border-radius: 0;
    height: 100%;
}

input[type="range"] {
    -webkit-appearance: none;
    height: 7px;
    width: 100%;
    background: var(--input-border-color);
    border-radius: 5px;
    background-image: linear-gradient(var(--icon-color), var(--icon-color-hover));
    background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--icon-color);
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
}

input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

