.icon-button {
    display: flex;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: rgba(0,0,0,0.03);
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    align-items: center; 
}

.icon-button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    opacity: 0;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    pointer-events: none;
}  

.icon-button:active::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}

.icon {
    color: var(--icon-color);
    transition: all .3s;
    width: 1em;
    height: 1em;
    fill: currentColor;
    font-size: 1.5rem;
}

.icon-button:hover .icon,
.icon:hover {
    color: var(--icon-color-hover);
    transform: scale(1.1);
}