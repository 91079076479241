@font-face {
    font-family: 'Roboto'; 
    font-weight: 300; 
    src: url('./fonts/roboto-light-webfont.woff2') format(woff2),
          url('./fonts/roboto-light-webfont.woff') format(woff);
    font-display: swap;
  }
  
  * {    
    font-family: 'Roboto', sans-serif;  
    margin: 0;
    padding: 0;
    box-sizing: border-box;  
    letter-spacing: 0.07em; 
    caret-color: transparent;
  }
  
  :root {   
    --wrapper-padding: 40px;
    --primary-color: #06f;
    --primary-color-dark: #0066CC;
    --primary-color-light: #7eb1fc;

    --secondary-color: #252d40;
    --cta-green: #13ae4b;
    --cta-red: red;

    --cta-green-dark: #0B652B;
    --cta-discount: #F0FDF0;
    --cta-discount-two: #E1FAE1;
    --cta-required: #FCECEE;
    --cta-processing: #FF3D33;
    --cta-red: red;
    --cta-red-dark: #da0303;
    --cta-blue: #0cf;

    --speed: 300ms;    
    --transition-speed: 500ms;   
    --icon-font-size: 1.5rem;    
  
    --icon-color: #111;
    --icon-color-hover: #999;
    --icon-button-background: rgba(0,0,0,0.03);
    --icon-button-background-hover: rgba(0,0,0,0.05);

    --link-text-color: #111;
    --link-text-color-hover: #999;

    --foreground: #222;
    --background: #f1f2f6;
    --card-background: #fff;
    --widget-background: #fff;
    --dropdown-background: #fff;

    --tooltip-background: #333;
    --tooltip-text-color: #fff;

    --footer-text-color: #111;

    --box-shadow: 0 14px 18px 5px #00000017;
    --border-color: #ddd;  

    --button-disabled-background: #ddd;
    --button-disabled-color: #bbb;
    --button-text-color: #fff;

    --table-head-background: #eee;
    --table-border-color: #fff;
    --alternate-rows: #f9f9f9;
    --table-row-open: #555;
    --reverse-border-color: #444;   

    --input-background-color: #f8f8f8;
    --input-background-color-hover:#fff;
    --input-text-color: #333333;
    --input-border-color: #ccc;   
    --placeholder-color: #333; 
    
    --select-background-color: #fff;
    --select-background-color-hover: #f1f2f6;

    --richtext-toolbar-background: #fff;
    --richtext-toolbar-buttons: #f1f2f6;
    
    --transparent-background: rgba(255,255,255,1);

    --modal-background: #fff;

    --paragraph-text-color: #333;

    --h1-color: #4F4F4F;
  }  
  .darkmode {   
    --box-shadow: 0 14px 18px 5px #00000047;
    --border-color: #444;  

    --cta-discount: var(--input-background-color);
    --cta-discount-two: var(--background);

    --icon-color: var(--primary-color);
    --icon-color-hover: var(--primary-color-dark);

    --icon-button-background: #2b2b2b;
    --icon-button-background-hover: #333;

    --link-text-color: #999;
    --link-text-color-hover: var(--primary-color);

    --foreground: #ffffff;
    --background: #333;
    --card-background: rgba(0,0,0,0.75);
    --widget-background: rgb(68, 68, 68);

    --dropdown-background: #222;    

    --tooltip-background: #f1f2f6;
    --tooltip-text-color: #222;

    --footer-text-color: #999;

    --button-disabled-background: #222;
    --button-disabled-color: #555;
    --button-text-color: #fff;

    --table-head-background: #111;
    --table-border-color: #444;
    --alternate-rows: #222;
    --table-row-open: #fff;
    --reverse-border-color: #eee;  

    --input-background-color: #444;
    --input-background-color-hover: rgba(255,255,255,0.1);
    --input-text-color: #ccc;
    --input-border-color: #666; 
    --placeholder-color: var(--primary-color); 

    --select-background-color: #222;
    --select-background-color-hover: #333;

    --richtext-toolbar-background: #111;
    --richtext-toolbar-buttons: #999;    
    
    --transparent-background: rgba(0,0,0,0.75);
    --modal-background: #111;
    --paragraph-text-color: #ddd;
    --h1-color: #C2C2C2;
  }
  
  body {
    background: var(--background);
    color: var(--foreground);   
    transition: all var(--speed);
    overflow-x: hidden;
  }
  
  .background-panel {
    background: var(--card-background);
    transition: all var(--speed);
  }
  
  #root {
    display: flex; 
    flex-direction: column;    
    min-height: 100vh;    
  }

  #modal-root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 6;
  }
  
  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;  
    position: relative;   
    min-height: calc(100vh - 100px);  
  }
  
  /* Layout Styles */ 
  .container {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .contained {
    max-width: 1366px;
    margin: 0 auto;
  }
  
  .wrapper {
    width: 100%; 
    padding: var(--wrapper-padding);  
  }

  .wrapper-x {
    width: 100%; 
    padding: var(--wrapper-padding) 0;  
  }
  
  .parent,
  .mobile-parent {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  } 
  
  /* Element Styles */
  h1 {
    margin-bottom: 40px;
    color: var(--h1-color);
    font-weight: 300;
  }
  h2 {
    margin-bottom: 40px;
    font-weight: 300;
  }
  h3 {
    color: var(--h1-color);
    margin-bottom: 40px;
    text-align: center;
  }
  h4 {
    font-weight: 300;
  }
  h5 {
    color: var(--h1-color);
  }
  h6 {
    color: var(--foreground);
    font-weight: 300;
  }
  
  p {
    font-size: 14px;
    line-height: 1.8rem;
    color: var(--paragraph-text-color);
  }
  
  small {
    font-size: 12px;
  }

  i {
    font-family: 'Monotype Corsiva', cursive;
  }
  
  ul {
    list-style: none;
  }
  .bullet-list {
    list-style-type: disc;
    list-style-position: inherit;
    padding-left: 20px;
  }
  li {
    font-size: 14px;
  }
  li::marker {
    color: var(--icon-color);
    font-size: 1rem;
  }
  
  a {
    color: var(--link-text-color);
    text-decoration: none;
    transition: all var(--speed);
  }  
  a:hover {
    color: var(--link-text-color-hover);
  } 
  
  img {   
    width: 100%; 
    height: auto; 
    display: inline-block;  
  } 
  .object-fit {
    height: 100%;
    width: 100%;
    object-fit: cover;
  } 
  .lazy-load-image-background {
    height: 100%;
    margin: 0 auto;
  }
@media ( max-width: 768px ) {  
  .parallax {
    display: none;
  }
}
  /* Tables */
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }
  th,
  td {
    font-size: 12px; 
    vertical-align: middle;
    padding: 20px 10px;
    text-align: left;    
    border: solid 1px var(--table-border-color);
  }  
  .order-list td {
    padding: 0 10px;
  }
  .order-list td:last-child {
    padding: 0;
  }
  
  /* Scrollbar Styles */
  ::-webkit-scrollbar {
    width: 12px; 
    height :12px;
  }  
  ::-webkit-scrollbar-track-piece {
    background: var(--background);
    /* background: red; */
  } 
  ::-webkit-scrollbar-button {
    background:#ccc;
    background:var(--input-border-color);
  }
  ::-webkit-scrollbar-thumb {
    background: var(--icon-color-hover); 
    border-radius: 10px; 
    border: solid 3px var(--background);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #999; 
    opacity: 0.5;
  }

  /* Alert Styles */
/* #__react-alert__ {
  position: relative;
  z-index: 9999;
} */
/* #__react-alert__ > div:before {
  content: '';
  background: rgba(0,0,0,0.5);
  position: fixed;
  width: 100vw;
  height: 200vh; 
} */
#__react-alert__ > div > div > div {
  /* background: white !important; */
  /* color: #333 !important;  */
  text-transform: capitalize !important;  
  border-radius: 5px !important;
  /* padding: 40px !important; */
  /* position: relative; */
  
}
/* #__react-alert__ button {
  position: absolute;
  top: 10px;
  right: 10px;
} */
#__react-alert__ button svg {
  stroke: #999 !important;    
}
  
  /* Helper (BootStrap) Classes */
  .d-flex {
    display: flex;
  }
  .gap-2 {
    gap: 20px;
  }
  .gap-4 {
    gap: 40px;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-end {
    justify-content: flex-end;
  }
  .direction-column {
    flex-direction: column;
  }
  .align-items-center {
    align-items: center;
  }
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
    top: 0;
    left: 0;
  }
  .inline-block {
    display: inline-block;
  }
  .fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  .whitespace-nowrap {
    white-space: nowrap;
  }
  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .zoom-in {
    cursor: zoom-in;
  }
  .scroll-x {
    overflow-x: auto;
  }
  .scroll-y {
    overflow-y: auto;
  }
  .fullWidth {
    width: 100%;
    max-width: none !important;
  }
  .fullHeight {
    height: 100%;
  }
  .screenheight {
    height: 100vh;
  }
  .float-r {
    float: right;
  }
  .hidden {
    display: none;
  }
  .round {
    border-radius: 50%;
    overflow: hidden;
  }
  .mobile {
    display: none;
  }
  .danger {
    color: var(--cta-red);
  }
  .success {
    color: var(--cta-green-dark);
  }
  .processing {
    color: var(--cta-blue);
  }
  
  @media ( max-width: 768px ) {   
    :root {
      --transition-speed: 300ms;
      --wrapper-padding: 30px;
    }  
    .parent {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    } 
    .reverse {
      flex-direction: column-reverse;
    }
    .flex-mobile {
      flex-direction: row;
    }
    .paragraph {
      padding-left: 40px;
      padding-right: 40px;
    }
    .mobile-center {
      text-align: center;
    }  
  }
  @media ( max-width: 601px ) {   
    .flex-mobile {
      flex-direction: column;
    }
  }
  
  @media ( max-width: 501px ) { 
    :root {
      --transition-speed: 250ms;
      --wrapper-padding: 20px;
    }   
    .mobile {
      display: block;
    } 
    header nav > div:last-child {
        flex: none !important;
    }
    .large-screen {
      display: none;
    }  
    .mobile-parent {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }  
  }
  