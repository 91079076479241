/* Richtext Editor */
.rt-preview {
    border: solid 1px var(--border-color);
    background: var(--background);
    padding: 10px;
}
.richtext-editor {
    border: solid 1px var(--border-color);
    background: var(--richtext-toolbar-background);
}
.editor-area {
    height: 250px;
    border: solid 1px var(--input-border-color);
    margin-top: 20px;
    padding: 20px;
    background: var(--input-background-color);
}

.public-DraftEditorPlaceholder-root {
    color: var(--input-text-color);    
}
  .rdw-fontfamily-optionwrapper {
    width: 160px;
  }
  .rdw-fontfamily-optionwrapper li:nth-child(1) {
    font-family: 'Roboto', sans-serif;
  }
  .rdw-fontfamily-optionwrapper li:nth-child(2) {
    font-family: 'Arial', sans-serif;
  }
  .rdw-fontfamily-optionwrapper li:nth-child(3) {
    font-family: 'Georgia', serif;
  }
  .rdw-fontfamily-optionwrapper li:nth-child(4) {
    font-family: 'Impact', sans-serif;
  }
  .rdw-fontfamily-optionwrapper li:nth-child(5) {
    font-family: 'Tahoma', sans-serif;
  }
  .rdw-fontfamily-optionwrapper li:nth-child(6) {
    font-family: 'Times New Roman', sans-serif;
  }
  .rdw-fontfamily-optionwrapper li:nth-child(7) {
    font-family: 'Verdana', sans-serif;
  }
  .rdw-fontsize-dropdown ul {
    position: absolute;
    width: 130px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(1) {
    font-size: 8px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(2) {
    font-size: 9px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(3) {
    font-size: 10px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(4) {
    font-size: 11px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(5) {
    font-size: 12px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(6) {
    font-size: 14px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(7) {
    font-size: 16px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(8) {
    font-size: 18px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(9) {
    font-size: 24px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(10) {
    font-size: 30px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(11) {
    font-size: 36px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(12) {
    font-size: 48px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(13) {
    font-size: 60px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(14) {
    font-size: 72px;
  }
  .rdw-fontsize-dropdown ul li:nth-child(15) {
    font-size: 96px;
  }
  .rdw-block-wrapper ul li:nth-child(1) {
    font-size: 14px;
  }
  .rdw-block-wrapper ul li:nth-child(2) {
    font-size: 40px;
  }
  .rdw-block-wrapper ul li:nth-child(3) {
    font-size: 32px;
  }
  .rdw-block-wrapper ul li:nth-child(4) {
    font-size: 16px;
    font-weight: 400;
  }
  .rdw-block-wrapper ul li:nth-child(5) {
    font-size: 14px;
  }
  .rdw-block-wrapper ul li:nth-child(6) {
    font-size: 14px;
  }
  .rdw-block-wrapper ul li:nth-child(7) {
    font-size: 10px;
  }
  .rdw-block-wrapper ul li:nth-child(8) {
    font-size: 14px;
  }
  .rdw-block-wrapper ul li:nth-child(9) {
    font-size: 14px;
  }
  .rdw-link-decorator-icon {
    width: 14px;
    height: 14px;
  }
  /* Richtext Preview Styles */
  .richtext-output div,
  .richtext-output figure {
    text-align: center !important;    
    float: none !important;
    margin: 0 auto !important;
  }
.richtext-output li {
  font-size: 14px;
}
.richtext-output p {
  margin-bottom: 20px
}
.richtext-output p:last-child {
  margin-bottom: 0
}
.richtext-output ul {
  list-style-position: inside;
  list-style-type: disc; 
}
.richtext-output ol {
  list-style-position: inside;
}
.richtext-output h3,
.richtext-output h4 {
  margin: 20px 0;
}
.richtext-output h5,
.richtext-output h6 {
  margin: 10px 0;
}
.richtext-output a {
  text-decoration: underline;
}
.rdw-option-wrapper {
  background: var(--richtext-toolbar-buttons);
  border-color: var(--border-color);
  transition: all var(--speed);
}
.rdw-option-wrapper:hover {
  box-shadow: 1px 1px 0px var(--input-border-color);
  background: white;
}

.rdw-dropdown-wrapper {
  background: var(--input-background-color);
  box-shadow: none;
  border: none;
  border-bottom: solid 1px var(--input-border-color);
  color: #999;
  transition: all var(--speed);
}
.rdw-dropdown-wrapper:hover {
  background: var(--input-background-color-hover);
  border-color: var(--primary-color);
  box-shadow: none;
}
.rdw-dropdown-optionwrapper { 
  border: 1px solid var(--border-color);
}
.rdw-dropdown-optionwrapper li { 
  background: var(--select-background-color); 
  transition: all var(--speed);
  border-bottom: solid 1px var(--border-color);
}
.rdw-dropdown-optionwrapper li:hover { 
  background: var(--select-background-color-hover); 
}
.rdw-dropdown-optionwrapper:hover {
  box-shadow: none;
}

.rdw-image-modal,
.rdw-colorpicker-modal,
.rdw-link-modal,
.rdw-embedded-modal,
.rdw-emoji-modal {
  background: var(--select-background-color); 
  border-color: var(--border-color);
  box-shadow: var(--box-shadow);
  z-index: 2;
  height: auto;
}

.rdw-image-modal-btn,
.rdw-link-modal-btn,
.rdw-embedded-modal-btn {
  color: white !important;
  background: var(--primary-color) !important;
  justify-content: center;
  border: none;
  display: inline-block;  
}

.rdw-image-modal-alt-input,
.rdw-image-modal-size-input,
.rdw-link-modal-input,
.rdw-embedded-modal-link-input,
.rdw-embedded-modal-size-input {
  border-color: var(--input-border-color);
  color: var(--input-text-color);
}

.rdw-image-modal-upload-option {
  background: var(--card-background);
}

.DraftEditor-root {
  height: auto;
}
.rdw-dropdown-selectedtext {
  color: var(--icon-color);
}

.rdw-dropdown-carettoopen {
  border-top-color: var(--icon-color);
}


.rdw-dropdown-carettoclose {
  border-bottom-color: var(--icon-color);
}
.richtext-output p {
  color: var(--footer-text-color);
}