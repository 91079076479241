/* modal styles */

#lightbox-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 6;
}

.lightbox-modal {
    height: 100vh;     
    background: rgba(0,0,0,.9);     
}