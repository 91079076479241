/* container styles */

.lightbox-thumbs-container { 
    min-height: 85px;
    background: rgba(0,0,0,.75);
    position: relative;
}

.lightbox-portrait .lightbox-thumbs-container {   
    width: 85px;
    height: 100%;
    padding: 48px 0;
}

/* stage styles */

.lightbox-thumbs-stage {
    height: 100%;            
    position: relative;
    overflow: hidden;  
    display: flex;
}

.lightbox-portrait .lightbox-thumbs-stage {
    width: 85px;  
}

/* slider styles */

.lightbox-thumbs-slider {
    display: flex;
    gap: 5px;
    padding: 5px;
    transition: all .3s ease-in-out;
}

.lightbox-portrait .lightbox-thumbs-slider {
    flex-direction: column;
}

/* image styles */

.lightbox-thumbs-slider img {    
    height: 75px;
    width: 75px;
    object-fit: cover;
    opacity: .75;
    border: solid 1px transparent;
    transition: all .3s ease-in-out;
    cursor: pointer;
}

.lightbox-thumbs-slider img:hover,
.lightbox-thumbs-slider img.active {
    opacity: 1;
    border-color: #999;    
}

.lightbox-thumbs-slider img.active {
    cursor: auto;
}

/* button styles */

.lightbox-thumbs-container button {
    position: absolute;
    top: 0;
    justify-content: center;
    background: rgba(0,0,0,.7);
    width: 48px;
    height: 100%;
    z-index: 1;   
    transition: none; 
}

.lightbox-thumbs-container button .icon {
    color: #eee;
}

.lightbox-thumbs-container button:hover .icon {
    color: #fff;
}

.lightbox-portrait .lightbox-thumbs-container button {
    height: 48px;
    width: 85px;
    top: 48px;
}

.lightbox-thumbs-container button:last-child {
    right: 0;
}

.lightbox-portrait .lightbox-thumbs-container button:last-child {
    right: auto;
    top: auto;
    bottom: 48px;
}

.lightbox-thumbs-container button[disabled] {
    background: none;
    opacity: 0;
    cursor: not-allowed;
    visibility: hidden;
}

/* media queries */

@media (max-width: 701px) {    

    .lightbox-portrait .lightbox-thumbs-container {        
        padding-top: 84px;
    }

    .lightbox-portrait .lightbox-thumbs-container button {
        height: 48px;
        width: 85px;
        top: 84px;
    }

}