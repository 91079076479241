.tooltip-container {
    position: relative;
    display: inline-block;
}  
.tooltip {
    position: absolute;  
    left: 50%;
    transform: translateX(-50%);  
    background: #333;
    color: white;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
    z-index: 1;
    white-space: normal; 
    text-align: center;
}  
.tooltip-container:hover .tooltip {
    opacity: 1;
    visibility: visible;
}