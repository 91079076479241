.showroom { 
  display: flex; 
  justify-content: center; 
  flex-wrap: wrap; 
  /* min-height: 460px;   */
  margin: 0 auto;
}
.showroom-item {
    display: flex; 
    flex-direction: column;   
    justify-content: space-between;
    flex: 1 0 200px;
    max-width: 300px;
    align-items: center;
    height: 420px; 
    padding: 20px; 
    background: var(--widget-background);
    margin-bottom: 40px;                              
    transition: all var(--speed);
    box-shadow: 0 2.8px 2.2px   rgb(0 0 0 / 3%), 
                0 6.7px 5.3px   rgb(0 0 0 / 5%), 
                0 12.5px 10px rgb(0 0 0 / 6%), 
                0 22.3px 17.9px rgb(0 0 0 / 7%), 
                0 41.8px 33.4px rgb(0 0 0 / 9%), 
                0 100px 80px rgb(0 0 0 / 12%);
}
.showroom-item:hover {
    transform: scale(1.05);    
}
.showroom-item > div {
    display: flex; 
    flex-direction: column; 
    justify-content: space-between;
    align-items: center;  
    height: 50%;
    width: 100%;
    max-width: 240px;
}
.showroom-item figure {
    display: flex;
    width: 90%;
    overflow: hidden;
    text-align: center;
}
.showroom-item > div:first-child {   
    justify-content: center;
    align-items: center;
    /* height: 50%; */
}
.showroom-item .button {
    padding: 20px;
}
.showroom-item h4 {
    font-size: 14px;
}