.thumbnails-body {
  width: 100%;
  /* max-width: 700px; */
  position: relative;
}
.thumbnails-holder {
    overflow: hidden;    
    white-space: nowrap;  
    position: relative;  
}
.thumbnails-slider {
    --items-per-screen: 8;
    --slider-index: 0;
    transform: translateX(calc(var(--slider-index) * -100%));
    transition: transform 250ms ease-in-out;
    margin: 0 -5px;
}
.thumbnails-slider div { 
    width: calc(100% / var(--items-per-screen) - 10px); 
    margin: 0 5px;
    border: solid 1px var(--border-color);
    overflow: hidden;
    display: inline-block;
    cursor: pointer; 
    transition: all var(--speed);
    cursor: zoom-in;
    font-size: 0;
    height: 100px;
}
.thumbnails-slider figure {
  height: 100%;
  text-align: center;
}
.thumbnails-slider div.active {
  border: solid 1px var(--primary-color);
}
.thumbnails-slider div:hover {
    box-shadow: 0px 0px 10px  rgba(0,0,0,0.1); 
} 
.thumbnails-controls {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(calc(-50% - 5px));
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.thumbnails-controls button {
  position: absolute;
  top: -24px;
  left: 0;  
}
.thumbnails-controls button:last-child {  
  left: auto;  
  right: 0;
}
.progress-bar {
    display: flex;
    gap: .25rem;
    justify-content: flex-end;
    margin: 5px 0;
}
.progress-bar li {
    min-width: 1.5rem;
    height: .5rem;
    background-color: var(--card-background);
    border-radius: 5px;
}
.progress-bar li.active {
    background-color: var(--primary-color);
}

@media (max-width: 1750px) {  
  .thumbnails-slider {
    --items-per-screen: 7;
  }   
}
@media (max-width: 1500px) {  
  .thumbnails-slider {
    --items-per-screen: 6;
  }   
}
@media (max-width: 1250px) {  
  .thumbnails-slider {
    --items-per-screen: 5;
  }   
}
@media (max-width: 980px) {  
  .thumbnails-slider {
    --items-per-screen: 3;
  }  
}
@media (max-width: 768px) {
  .thumbnails-slider {
    --items-per-screen: 3;
  }  
}
@media (max-width: 480px) {
  .thumbnails-slider {
    --items-per-screen: 2;
  }
}