header {
    border-bottom: solid 1px var(--border-color);
    position: sticky;
    top: 0;
    z-index: 6;
}
header nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
}
header nav > div {
    display: flex;    
    align-items: center;
    gap: 10px;
    padding: 5px;
}  
header .dropdown-menu {  
    position: absolute;
    top:100%;
    right: 0;
    gap: 0.7rem;
    z-index: 2;
    background: var(--transparent-background);
    box-shadow: var(--box-shadow);
    border: solid 1px var(--border-color);
    border-bottom-left-radius: 15px;
}
header .dropdown-menu a,
header .dropdown-menu span {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 10px;
    text-align: right;
    white-space: nowrap;
    padding: 5px 10px;
    color: var(--link-text-color);
    transition: all var(--speed);
    cursor: pointer;
}
header .dropdown-menu a:hover,
header .dropdown-menu span:hover {    
    color: var(--link-text-color-hover);
}
header .nav-dropdown {
    width: 100%;
}
header .nav-dropdown > div {
    max-width: 1366px;
    margin: 0 auto;
}
header .nav-dropdown h6 {
    margin-bottom: 20px;
}
header .nav-dropdown ul {
    list-style-type: disc;
    list-style-position: inherit;
    padding-left: 20px;
}
header .nav-dropdown li a  {
    font-size: 12px;
    justify-content: flex-start;
}
header .nav-dropdown h6 {
    font-size: 13px;
    font-weight: bold;
    color: var(--icon-color-hover)
}
header nav > div:last-child {
    flex: 1;
}

.search {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 24px;
    background: var(--input-background-color); 
    height: 48px;
}
.search label {       
    flex-grow: 1;
}
.search input {
    text-indent: 1rem; 
    line-height: 46px;
    flex: 1;
    color: var(--input-text-color);
}
.search span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: var(--icon-button-background);
    border-radius: 50%;
}
.search-results {
    background: var(--card-background);
    padding: 40px;
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    width: 100%;
    box-shadow: var(--box-shadow);
    z-index: 2;
}  
.logo {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--background);
    border: solid 1px var(--border-color);
} 
.toggle {
    --width: 30px;
    --height: calc(var(--width) / 2);
    --border-radius: calc(var(--height) / 2);  
    display: inline-block;
    cursor: pointer;
}
.toggle-input {
    display: none;
}
.toggle-fill {
    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius);
    background: #999;
    transition: background 0.2s;
}  
.toggle-input:checked ~ .toggle-fill {
    background: #ccc;
}  
.toggle-fill::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: var(--height);
    width: var(--height);
    background: var(--foreground);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: var(--border-radius);
    transition: transform 0.2s;
}  
.toggle-input:checked ~ .toggle-fill::after {
    transform: translateX(var(--height));
}