.linear-progress-container {
    height: 4px; 
    background: rgba(255, 255, 255, 0.3); 
}
  
.linear-progress-bar {
    height: 100%;
    background: linear-gradient(to right, rgba(255,255,255, .9), rgba(255,255,255, .5));
    width: 0; 
    transition-property: width;
}