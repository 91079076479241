.circular-progress-outer {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 8px solid #555;
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}

.circular-progress-inner {
  height: 100%;
  border-radius: 50%;
  border: 8px solid #eee;
  border-top-color: transparent;
  border-right-color: transparent;
  transform: rotate(0deg);
  animation: spinCircle 2s linear infinite;
}

@keyframes spinCircle {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}