.modal {
    float: right;
    width: 100%;
    position: relative;
    z-index: 2;
    background: white;
    border-left: solid 1px var(--border-color);
    overflow-y: auto;
    max-width: 480px;
}
.modal h2 {
    color: var(--icon-color);
}
.modal .cart-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: #EB0000; 
    transform: rotate(45deg);
    width: 100%;   
    top: 50px; 
    right: -150px;
    gap: 10px;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    z-index: 1;
}
.darkmode .modal {
    background: #111;
}
.discount {
    background: var(--cta-discount) !important;
}
.discount-two {
    background: var(--cta-discount-two) !important;
}

/* Button  styles */
.pointer-events-none {
    pointer-events: none;
    opacity: .3;
}
button {
    outline: none;
    border: none;
    text-transform: uppercase;
    transition: all var(--speed);
    cursor: pointer;
    background: none;
    display: flex;
    align-items: center;    
    font-size: 1rem;
}
button[disabled] {
    pointer-events: none;
    background: var(--button-disabled-background);
    color: var(--button-disabled-color);
    box-shadow: none;
} 

.button {
    justify-content: center;
    box-shadow: 
    0px 3px 1px -2px rgb(0 0 0 / 20%), 
    0px 2px 2px 0px rgb(0 0 0 / 14%), 
    0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-position: center;
    transition: background 0.8s, box-shadow var(--speed);
    padding: 40px 18px;
    color: var(--button-text-color);
    background: var(--primary-color);
    outline: none;
    width: 100%;
    /* font-size: 1.1rem;
    font-weight: bold; */
}
.button:hover {
    box-shadow: 
    0px 2px 4px -1px rgb(0 0 0 / 20%), 
    0px 4px 5px 0px rgb(0 0 0 / 14%), 
    0px 1px 10px 0px rgb(0 0 0 / 12%); 
    background: var(--primary-color-dark) radial-gradient(circle, transparent 1%, var(--primary-color-dark) 1%) center/15000%;
    color: white;
}
.button:active {
    box-shadow: 
    0px 5px 5px -3px rgb(0 0 0 / 20%), 
    0px 8px 10px 1px rgb(0 0 0 / 14%), 
    0px 3px 14px 2px rgb(0 0 0 / 12%);
    background-color: var(--primary-color);
    background-size: 100%;
    transition: all 0s;
    color: white;
}

.button-secondary {
    background-color: var(--primary-color-dark);
}
.button-secondary:active {
    background-color: var(--primary-color-dark);
}
.button-secondary:hover {
    background: var(--primary-color) radial-gradient(circle, transparent 1%, var(--primary-color) 1%) center/15000%;
}

.danger-button {
    background-color: var(--cta-red);
}
.danger-button:hover {
    background: var(--cta-red-dark) radial-gradient(circle, transparent 1%, var(--cta-red-dark) 1%) center/15000%;
}
.danger-button:active {    
    background-color: var(--cta-red);
    background-size: 100%;
}

.mobile-button.icon-button {    
    display: none;    
}  

.icon-button {
    display: flex;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: var(--icon-button-background);
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    align-items: center; 
}
.icon-button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    opacity: 0;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    pointer-events: none;
}  
.icon-button:active::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}
.icon-button:hover {
    background: var(--icon-button-background-hover);
}
.icon-button:hover .icon {
    transform: scale(1.2);
} 

.icon {
    color: var(--icon-color);
    transition: all var(--speed);
    width: 1em;
    height: 1em;
    fill: currentColor;
    font-size: var(--icon-font-size);
}
.icon:hover,
.icon-button:hover .icon {
    color: var(--icon-color-hover);
}
.icon-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    /* background: var(--icon-color); */
    /* color: var(--background); */
    color: var(--foreground);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    font-size: 10px;
    font-weight: bold;
}


a.active,
aside li.active {
    color: var(--primary-color);
}
.active .icon {
    color: var(--link-text-color-hover);
}


/* background SVG styles */
.path {  
    stroke-width: .1;  
    stroke-linecap: round;                 
    fill: none;  
    stroke: var(--icon-color);
} 

.draw {
    stroke-dasharray: 120; 
    animation: draw 8s ease-in-out infinite;
}

@keyframes draw {
    0% {
        stroke-dashoffset: 120; 
        opacity: 0;                    
    }
    15% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        stroke-dashoffset: 0;
        opacity: 0;                    
    }
}

/* Tooltip Styles */
.tooltip-container {
    position: relative;
    display: inline-block;
}  
.tooltip {
    position: absolute;  
    left: 50%;
    transform: translateX(-50%);  
    background: var(--tooltip-background);
    color: var(--tooltip-text-color);
    padding: 4px 8px;
    font-size: 14px;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
    z-index: 1;
    white-space: normal; 
}  
.tooltip-container:hover .tooltip {
    opacity: 1;
    visibility: visible;
}
.tooltip-right {
    right: 0; 
    left: auto; 
    transform: translateX(0);
} 
.tooltip-bottom-left {
    top: 0;
    left: 100%; 
    transform: translateX(0);
} 

/* Dashboard styles */
.dashboard-container {
    margin-right: 300px;
}
.dashboard-panels {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
}
.dashboard-panel {
    background: var(--primary-color);
    border-radius: 15px;
    padding: 20px;
    display: flex;
    min-width: 150px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border: solid 1px transparent;
}
.dashboard-panel.orders {
    background: #8ad400;
}
.dashboard-panel.danger {
    background: #FF3D33;
}
.dashboard-panel.unavailable {
    background: #999;
}
.dashboard-panel.users {
    background: #5783db;
}
.dashboard-panel.reviews {
    background: #88744a;
}
.dashboard-panel.products {
    background: #00ccff;
}
.dashboard-panels .dashboard-panel {
    flex: 1;
} 
a.dashboard-panel:hover {
    background: var(--background);   
    border-color: var(--input-text-color); 
}
a.dashboard-panel:hover h2,
a.dashboard-panel:hover h3,
a.dashboard-panel:hover button {
    color: var(--input-text-color);    
}
.dashboard-panel button {
    color: white;
}
.dashboard-panel h2 {
    color: white;
    padding: 5px 0 10px 0;
    margin: 0;
}
.dashboard-panel h3 {
    color: white;
    padding: 10px 0 5px 0;
    margin: 0;    
}
.dashboard-panel .icon {
    color: white;
}
.dashboard-panel small {
    color: white;
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
}
.pagination a {
    height: 30px; 
    line-height: 30px; 
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination li.disabled a {
    pointer-events: none;
    opacity: 0.4; 
}
.pagination li.active a {
    color: #fff; 
    background: var(--icon-color); 
    border-color: var(--icon-color);
    border-radius: 100%;
    pointer-events: none;
}
.pagination a:hover {
    color: #fff; 
    background: var(--icon-color-hover); 
    border-color: var(--icon-color-hover);
}

.cart-table {
    background: var(--card-background);
    border: solid 1px var(--table-border-color);
}
.cart-table td {
    background: var(--background);
}
.cart-table th {
    border: none; 
    background: var(--input-background-color);   
    color: var(--icon-color);
    font-weight: 300;
}
.cart-table tr:hover td {
    transition: all var(--speed);
    background: var(--input-background-color);
}

.slide-buttons {
    margin: -80px auto 0 auto;
    position: relative;
    z-index: 2;
    max-width: 600px;
}

.thumbnails {
    display: flex;
    gap: 5px;
}
.thumbnails li {
    width: 75px;
}

hr {
    height: 1px; 
    background: linear-gradient(to right, transparent, var(--foreground), transparent);
    border: none;
    margin: 40px 0; 
}

.palette-container {
    position: fixed;
    bottom: 0;
    z-index: 2;

}
.palette {
    z-index: 2;
    position: relative;
}
.palette > div {
    display: flex;
    gap: 10px;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-100%, 0);    
    padding: 20px;  
    cursor: pointer;
    background: var(--dropdown-background); 
    border-top-right-radius: 15px;
    transition: transform 0.3s ease 0s;
    border: solid 1px var(--border-color);
    border-left: none;
}
.palette .open {
    transform: translate(0%, -100%);
    box-shadow: var(--box-shadow);
    transition: transform 0.3s ease 0.3s;    
    z-index: 0;
}
.palette span {
    opacity: 0;
    transition: opacity 0.3s ease 0.3s;
}
.palette .open span {    
    opacity: 1;
}

/* Aside / Article Styles */
aside {
    flex: 0 0 25%;
    max-width: 250px;
}
article {  
    width: 75%;
}
aside ul ul {
    margin: 20px 0 30px 0;
}
aside li {
    display: flex;
    align-items: center;
    padding: 2px 0;  
    position: relative;
    font-size: 12px; 
    color: var(--link-text-color);   
    cursor: pointer;  
    transition: all var(--speed);
    white-space: nowrap;
    min-height: 52px;
}
aside li:hover {    
    color: var(--link-text-color-hover);   
}
aside li li {
    display: block;
}
aside li a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;  
}
aside ul ul li {
    margin-left: 20px;
}

.rating-filter svg {
    transition: all var(--speed);
    cursor: pointer;
}
.rating-filter svg:hover {
    fill: var(--icon-color-hover);
}

.fade-in {
    opacity: 0;
    transform: translateY(40px);
    transition: all var(--speed) ease-in;
}
.fade-in.appear {
    opacity: 1;
    transform: translateY(0);
    overflow: hidden;
}

/* Calendar Styles */
.calendar {
    position: absolute;
    width: 100%;
    top: 59px;
    right: 0;
    z-index: 1;
    background: var(--background);    
}
.rdrDay {
    background: var(--icon-color);  
    border-radius: 50%;
}
.rdrMonth {
    background: var(--background);
    box-shadow: var(--box-shadow);
}
.rdrDayPassive {
    background: rgb(248, 248, 248);
}
.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {  
    border: none !important;
}
.rdrDay span {
    color: white;
}
.rdrDayHovered .rdrDayNumber:after {
    border: none !important;
}
.rdrDay.rdrDayDisabled {
    background: rgb(248, 248, 248);  
}
.rdrMonthAndYearPickers select {
    color:var(--input-text-color);
}

.filters {
    display: flex;
    flex-direction: column;
}
.filters h6 {
    margin: 40px 0px 20px;
}

.product-table th {
    vertical-align: initial;
    color: #999;
    text-align: right;
    font-size: 14px;
}
.product-table td {
    vertical-align: initial;
    font-size: 14px;
}
.product-table .icon {
    pointer-events: none;
}

.stepper-container {
    padding: 0 20px;
}
.stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.stepper span.line {
    border-bottom: solid 1px var(--border-color);
    flex: 1;
}
.stepper span.step {
    background: #333;
    width: 63px;
    height: 63px;
    border-radius: 50%;
    color: white;
    border: solid 15px var(--modal-background);
    display: flex;
    align-items: center;
    justify-content: center;
}
.stepper span svg {
    fill: white;
    font-size: 48px;
}
.stepper span.step.active {
    background: var(--primary-color);
}

.main-content,
.main-content p,
.main-content li {
    line-height: 1.8em;
    color: var(--paragraph-text-color);
}
.main-content h4 {
    color: var(--icon-color);
    margin: 40px 0 20px 0;
}

@media (max-width: 1366px) {
    .dashboard-container {
        margin-right: 0;
    }
}

@media (max-width: 768px) {   
    .filters {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
    .filters h6 {
        margin: 0 0px 20px;
    }
    .carousel-text {
        top: 8%;
        right: -35%;        
    }
    .carousel-text small {        
        font-size: 18px;       
    }
    .carousel-text li {
        font-size: 14px;
    }
    .mobile-button.icon-button {
        display: inline-block;        
    }
    aside,
    article,
    .parent > div {
        width: 100%;
        max-width: none;
    }
    aside {
        position: relative;
    }    
    .slide-buttons {
        margin: -40px auto 0 auto;        
    }    
}

@media (max-width: 400px) {   
    .carousel-text,
    .modal .cart-banner {
        display: none;
    }

}
