/* container styles */

.lightbox-container {
    display: flex;
    height: 100%; 
}

.lightbox-container.lightbox-landscape {
    flex-direction: column;
}

.lightbox-stage {    
    height: 100%;
    width: 100%;
    color: #fff;
    position: relative;
    overflow: hidden;
}

/* slide styles */

.lightbox-slide {
    position: absolute;      
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: all var(--transition-speed) ease-in-out; 
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    cursor: grab;
} 

.lightbox-slide.active {
    opacity: 1;
    visibility: visible;
    transition: transform var(--transition-speed) ease-in-out; 
    transform: translateX(0);
}

.lightbox-slide.previous {    
    transform: translateX(-100%);
}

.lightbox-slide.dragging {
    cursor: grabbing;
}

/* header/footer styles */

.lightbox-header,
.lightbox-footer {
    display: flex;    
    align-items: center;
    background: rgba(0,0,0,.5);
    transition: all var(--transition-speed);    
    width: 100%; 
    z-index: 1;
    opacity: 0;
    visibility: hidden;
}

.lightbox-header {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;  
    position: fixed; 
    padding-left: 10px;      
}

.lightbox-footer {
    justify-content: center;
    position: absolute;
    bottom: 0; 
    padding: 10px;    
    height: 48px;
}

.lightbox-header.active, 
.lightbox-footer.active {
    opacity: 1;
    visibility: visible;
}

.lightbox-playing .lightbox-header.active,
.lightbox-playing .lightbox-footer.active,
.lightbox-paused .lightbox-header.active,
.lightbox-paused .lightbox-footer.active {    
    opacity: 0;
    visibility: hidden;
}

.lightbox-title {
    display: flex; 
    align-items: center;
    gap: 20px;   
}

.lightbox-title i {
    display: block; 
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background: var(--primary-color);
}

.lightbox-icons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding: 12px;    
}

/* controls styles */

.lightbox-controls-container {
    position: absolute;
    bottom: 48px;    
    opacity: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: all .3s ease-out;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    width: 100%;
    padding: 10px;
}

.lightbox-playing .lightbox-controls-container,
.lightbox-paused .lightbox-controls-container {
    bottom: 0;
}

.lightbox-controls {    
    display: flex;   
    justify-content: center;
    align-items: center;
    gap: 20px;    
}

.lightbox-controls-container.visible,
.lightbox-controls-container:hover {
    opacity: 1;
}

/* button styles */

.lightbox-stop {
    position: absolute;   
    top: 10px; 
    right: 10px;
    z-index: 1;
}

/* icon styles */

.lightbox-controls .icon {
    color: white;
    transition: all .3s;
    width: 1em;
    height: 1em;
    fill: currentColor;
    font-size: 2rem;
}

/* image styles */

.lightbox-slide img {
    width: auto;
    height: auto;
    max-width: 100vw;    
    max-height: 100vh;
}

/* media queries */

@media (max-width: 701px) {    

    .lightbox-header {   
        flex-direction: column-reverse; 
        padding: 0 0 10px 0;
    }    

    .lightbox-icons {
        width: 100%;        
    }

}