footer {     
    border-top: solid 1px var(--border-color);
    text-align: center;    
    color: var(--footer-text-color);
    position: relative;    
}
footer .background-panel {
    position: relative;
    z-index: 1;
}
footer .parent > div {
    text-align: left;
}
footer h4 {
    margin: 40px 0;
    text-align: inherit;
}
footer .parent div:last-child {
    text-align: right;
}
footer .footer-links {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
}
footer .footer-links a {
    font-size: 14px;
}
footer .partners a {
    display: block;
    text-align: center;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    transition: none;
    margin: 0 10px;
}
footer .partners a:hover {
    background-position: 0px -50px;
}
@media (max-width: 768px) {   
    footer .parent > div,
    footer .parent > div h3 {        
        text-align: center !important;
    }
    footer .parent > div div {        
        justify-content: center;
    }
    footer .footer-links {        
        margin-top: 40px;
        margin: 40px auto;
    }  
}