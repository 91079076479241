/* Animation styles */
.stage {
    position: relative;
    min-height: 709px;
    transition: all var(--speed);
}
.stage ul li {
    padding-top: 40px;
}
.stage li > div {
    backface-visibility: hidden; 
    width: 100%;
}
.front {    
    transform: perspective(2000px) rotateY(0deg);
}
.frontTwo {
    transform: perspective(2000px) rotateY(180deg); 
}
.back {
    transform: perspective(2000px) rotateY(180deg); 
    position: absolute;
}
.backTwo {
    transform: perspective(2000px) rotateY(0deg); 
    position: absolute;
}
.anim .front,
.anim .backTwo {
    animation: spin  12s infinite linear;
}
.anim .back,
.anim .frontTwo {
    animation: spin2 12s infinite linear;
}

@keyframes spin {
    from {transform: perspective(2000px) rotateY(0);}
    to   {transform: perspective(2000px) rotateY(-360deg);}
}
@keyframes spin2 {
    from  {transform: perspective(2000px) rotateY(180deg);}
    to    {transform: perspective(2000px) rotateY(-180deg);}
}

.controls {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: all var(--speed);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.controls svg {
    cursor: pointer;
}
.stage:hover .controls {
    opacity: 1;
}
.stage:hover .front,
.stage:hover .back,
.stage:hover .frontTwo,
.stage:hover .backTwo {
    transition: all var(--speed);
}

/* Carousel Styles */
.carousel {
    width: 100%;
    max-width: 1024px;
    overflow: hidden; 
    margin: auto;  
}
.carousel .active {
    transform: scale(1);
}
.carousel ul {
    position: relative;
    white-space: nowrap;
}
.carousel ul.transition {
    transition: all var(--speed) ease-in-out;
}
.carousel li {
    width: calc(100% / 3);  
    display: inline-block;
    overflow: hidden;
    transform: scale(0.9);
}
.carousel ul.transition li {
    transition: all var(--speed) ease-in-out;
}

.carousel-text {
    position: absolute;
    top: 10%;
    right: -38%;
    background: #EB0000; 
    transform: rotate(45deg);
    width: 100%;    
}
.carousel-text li div {
    display: flex;   
    align-items: center;
    gap: 10px; 
    justify-content: center;
}
.carousel-text svg {
    color: white;
}
.carousel-text li {
    font-weight: bold;
    font-size: 22px;    
    color: white; 
    padding: 20px 0 !important;    
    width: 100%;  
}

@media ( max-width: 1366px ) {   
    .stage {
        min-height: calc(100vw / 2);
    }
}